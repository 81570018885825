/*!
Adds a text reader friendly summary of the progress bar.
If errors: Create message at top of form. Put message near errorred field
 */

export default function formatProgressBar() {
  // console.log('formatProgressBar...');
  const $progressBar = jQuery('div.webform-progressbar');

  if ($progressBar.length <= 0) {
    console.log('No progress bar, not running.');
    return;
  }

  const $progressBarComplete = $progressBar.find('.completed').last();
  const $progressBarCompleteText = $progressBarComplete.find('.webform-progressbar-page-label').text();
  const $progressBarCurrent = $progressBar.find('.current');
  const $progressBarCurrentText = $progressBarCurrent.find('.webform-progressbar-page-label').text();
  const $progressBarRemaining = $progressBarCurrent.nextAll('.webform-progressbar-page');
  const $progressBarRemainingNum = $progressBarRemaining.length;

  let completeText = '';
  let $pageText = 'pages';
  let $areText = 'are';
  let remainingText = '';

  if($progressBarComplete.length > 0) {
   completeText = `"${$progressBarCompleteText}" complete.`;
  }

  if($progressBarRemaining.length === 1) {
    $pageText = 'page';
    $areText = 'is';
  }

  if ($progressBarRemaining.length > 0) {
    remainingText = `There ${$areText} ${$progressBarRemainingNum} ${$pageText} remaining.`;
  }

  // console.log('$progressBar', $progressBar);
  const $message = `
    <div tabindex="0" class="message--progress-bar visually-hidden" role="heading">
    Form progress: ${completeText} You are currently at: "${$progressBarCurrentText}". ${remainingText}
    </div>`;
  jQuery($message).insertBefore($progressBar);
};
