/*!
Check webform fields for errors.
If errors: Create message at top of form. Put message near errorred field
 */

export default function formatErrors() {
  // console.log('Check webform fields for ');
  const $form = jQuery('form.webform-client-form');

  // Only run if form found:
  if($form.length <= 0) {
    console.log('No form found, not running.');
    return;
  }
  const $formItems = $form.find('div.form-item');
  const $formItemsErrored = $formItems.find('.error');

  // Only proceed if error field are present:
  if($formItemsErrored.length <= 0) {
    console.log('No form errors found, not running.');
    return;
  }

  // Add invalid to field:
  $formItemsErrored.attr('aria-invalid', true);
  
  const numErrors = $formItemsErrored.length;

  const $labels = [];
  const $labelsText = [];
  let $description;
  let $fieldID;
  let $fieldIDdesc;
  
  // Get labels:
  $formItemsErrored.each(function(i, el) {
    const $item = jQuery(el);
    $labels.push($item.prev('label'));
    $fieldID = $item.attr('id');
    console.log('fieldID', $fieldID)
    $description = $item.next('div.description');
    console.log('description', $description)
    
    // Add aria described by attribute based on field ID name.
    // Apply ID name to the description div if one exists.
    if($description.length > 0 && $fieldID.length > 0) {
      $fieldIDdesc = `desc-${$fieldID}`;
      $item.attr('aria-describedby', $fieldIDdesc);
      $description.attr('id', $fieldIDdesc);      
    }
    $description = '';
    $fieldID = '';
  });
  // console.log('labels', $labels.length);
  // console.log('labels', $labels);

  // Get labels text:
  jQuery($labels).each(function(i, el){  
    const fieldErrorMsg = '<span class="label__error-prefix">This field has an error: </span>';
    const $item = jQuery(el);
    const $itemText = $item.text();
    $labelsText.push($itemText);
    $item.html(`${fieldErrorMsg}${$itemText}`);
  });

  // Format labels text array for display:
  const labelsFormatted = $labelsText.join(', ');

  const $message = `
    <div id="messages"  class="grid-32OFF message--error-message">
      <div class="messages error">
        <p role="alert" tabindex="0">The form has errors! Number of errors: ${numErrors}.<br/>
        The following field(s) have an error: ${labelsFormatted}</p>
      </div>
    </div>`;

  jQuery($message).insertBefore($form);

  // console.log('form', $form);
  // console.log('formItems', $formItems);
  // console.log('formItemsErrored', $formItemsErrored);
  // console.log('labelstext', $labelsText);
};
 