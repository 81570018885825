/*!
Check webform fields for errors.
If errors: Create message at top of form. Put message near errorred field
 */

// Import SCSS so it compiles:
import '../scss/cfaes-styles.scss';

import './components/format-errors';
import formatErrors from './components/format-errors';
import formatProgressBar from './components/format-progress-bar';
import formatTableField from './components/format-table-field';

jQuery(document).ready(function() {
  formatErrors();
  formatProgressBar();
  formatTableField();
});
