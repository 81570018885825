/*!
Checks webform for a table field "grid" component.
If found, adds accessibility labeled by or describe by to the table.
 */

export default function formatTableField() {
  // console.log('Check webform fields for ');
  const $gridEls = jQuery('.webform-component-grid');

  if($gridEls.length <= 0) {
    console.log('No grids found, not running.');
  }

  // Loop grids
  $gridEls.each( function(i, el) {
    // Get label and description for each grid.
    const $gridLabel = jQuery(el).find('label').first().text();
    const $gridDesc = jQuery(el).find('.description');
    const $gridDescText = $gridDesc.text();
    const $gridTable = jQuery(el).find('.webform-grid');
    // Add the label and description text as a caption to the grid table.

    const $caption = jQuery(`<caption class="visually-hidden">${$gridLabel}. ${$gridDescText}</caption>`);
    $caption.prependTo($gridTable);
    $gridDesc.attr('aria-hidden', true);
  });
};
 